import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ScrollToPlugin } from 'gsap/all';
import { useIntl } from 'gatsby-plugin-intl';
import { useMedia } from 'use-media';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ClientOnly from '../../components/clientOnly';
import Cursor from '../../components/cursor';
import { Section, Div, H1, H2, H3, NavLink } from '../../components/styles';
import Footer from '../../components/footer';
import Image from 'gatsby-image';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, MotionPathPlugin);

const IndexPage = ({ data }) => {
  const [isLoading] = useState(false);
  const isTablet = useMedia({ minWidth: '768px' });
  const Intl = useIntl();

  return (
    <Layout>
      <SEO
        title={Intl.formatMessage({ id: 'title' })}
        desc={Intl.formatMessage({ id: 'description' })}
        keywords={Intl.formatMessage({ id: 'keywords' })}
        noIndex
      />
      <Helmet>
        <script>
          {`var _paq = window._paq = window._paq || [];

          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="//ec2-18-197-155-62.eu-central-1.compute.amazonaws.com/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '2']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
          })();`}
        </script>
      </Helmet>
      <main>
        <ClientOnly>
          {isTablet && !isLoading && (
            <Cursor color="0, 255, 36" outerAlpha={1} innerSize={6} outerSize={16} outerScale={2} />
          )}
        </ClientOnly>

        <Section id="stay-tuned" bg="var(--section-bg-color)" data-section={1}>
          <Div
            className="trigger-white"
            display="grid"
            alignItems="center"
            justifyContent="center"
            py="60px"
            maxWidth="1000px"
            minHeight={['50vh', '60vh', '10vh', '10vh', '300px']}
            mx="auto"
            px={['0', '0', '0', '8rem']}
          >
            <H3
              fontSize={['1.4rem', '1.8rem', '2.8rem']}
              lineHeight={['2rem', '2.9rem', '4rem']}
              textAlign={['center']}
              // marginBottom={['-20rem', '-2rem', '0rem']}
              fontWeight={['500']}
            >
              <b>Stell Dir vor,</b> Du hättest <b>ein Team,</b> das für Dich
              <br /> <b>täglich die besten Artikel</b> aus dem Netz <b>filtert.</b>
            </H3>

            <H3
              fontSize={['1.4rem', '1.8rem', '2.8rem']}
              lineHeight={['2rem', '2.9rem', '4rem']}
              textAlign={['center']}
              marginBottom={['2rem', '4rem', '7rem']}
              fontWeight={['500']}
            >
              Das ist <b>piqd -</b> der <b>leistungsfähigste deutsche</b> <br />
              <b>Kuratierungsdienst</b> für journalistische Inhalte.
            </H3>

            <Div>
              <H3
                fontSize={['1.4rem', '1.8rem', '2.8rem']}
                // lineHeight={['2.9rem', '2.9rem', '4rem']}
                textAlign={['center']}
                fontWeight={['500']}
              >
                👉 <b>100 Kurator:innen</b>
              </H3>

              <H3
                fontSize={['1.4rem', '1.8rem', '2.8rem']}
                // lineHeight={['2.9rem', '2.9rem', '4rem']}
                textAlign={['center']}
                fontWeight={['500']}
              >
                👉 <b>19 Themenfelder </b>
              </H3>

              <H3
                fontSize={['1.4rem', '1.8rem', '2.8rem']}
                // lineHeight={['2.9rem', '2.9rem', '4rem']}
                textAlign={['center']}
                fontWeight={['500']}
              >
                👉 <b>mehr als 3000 Quellen </b>
              </H3>

              <H3
                fontSize={['1.4rem', '1.8rem', '2.8rem']}
                // lineHeight={['2.9rem', '2.9rem', '4rem']}
                textAlign={['center']}
                fontWeight={['500']}
              >
                👉 jeder Artikel <b>mit persönlicher Empfehlung</b>
              </H3>
              <H3
                fontSize={['1.4rem', '1.8rem', '2.8rem']}
                lineHeight={['2.9rem', '2.9rem', '4rem']}
                textAlign={['center']}
                fontWeight={['500']}
              >
                👉 <b>unabhängig und gemeinnützig!</b>
              </H3>
            </Div>

            <NavLink
              as="a"
              href="https://apps.apple.com/app/id1576473812"
              target="_blank"
              display="inline-flex"
              alignItems="center"
              textDecoration="none"
              color="inherit"
            >
              <H3
                fontSize={['1.2rem', '1.6rem', '2rem']}
                lineHeight={['2.9rem', '2.9rem', '4rem']}
                backgroundColor={['green']}
                textAlign={['right']}
                marginBottom={['10rem']}
                marginLeft={['31rem', '27rem', '23rem']}
                width={['10rem,30rem,40rem']}
                padding={['1rem', '1rem 2.5rem']}
              >
                100% Qualität. 0% Clickbait.
              </H3>
            </NavLink>

            <Image
              fixed={data.medwatch_landing.childImageSharp.fixed}
              alt="MedwatchLanding"
              style={{
                maxWidth: '800px',
                height: '700px',
                marginBottom: '7rem',
              }}
            />

            <H3
              fontSize={['1.4rem', '1.8rem', '2.8rem']}
              lineHeight={['2rem', '2.9rem', '4rem']}
              textAlign={['center']}
              fontWeight={['500']}
              marginTop={['0rem', '5rem']}
              marginBottom={['1rem']}
              width={['25%', '30%', '100%']}
              marginLeft={['29rem', '28rem', '0rem']}
            >
              Das alles gibt's <b>kostenlos per Push</b> auf Dein <b>iPhone!</b>
              <br />
              In einer
              <b> brandneuen, werbefreien App.</b>
            </H3>
            <Div>
              <H3
                fontSize={['1.4rem', '1.8rem', '2.8rem']}
                // lineHeight={['2.9rem', '2.9rem', '2,9rem']}
                textAlign={['center']}
                fontWeight={['500']}
              >
                👉 effizientes, ablenkungsfreies <b>Leseerlebnis</b>
              </H3>
              <H3
                fontSize={['1.4rem', '1.8rem', '2.8rem']}
                // lineHeight={['2.9rem', '2.9rem', '1rem']}
                textAlign={['center']}
                fontWeight={['500']}
              >
                👉 Artikel <b>speichern, teilen, snoozen</b>
              </H3>
              <H3
                fontSize={['1.4rem', '1.8rem', '2.8rem']}
                // lineHeight={['2.9rem', '2.9rem', '1rem']}
                textAlign={['center']}
                fontWeight={['500']}
              >
                👉 hohe <b>Datenschutzstandards</b>
              </H3>
            </Div>

            <H3
              fontSize={['1.4rem', '1.8rem', '2.8rem']}
              lineHeight={['2.9rem', '2.9rem', '4rem']}
              textAlign={['center']}
              fontWeight={['500']}
              marginTop={['2rem', '4rem', '10rem']}
              marginBottom={['1rem']}
            >
              Einfach die <b>App runterladen,</b>
              <br />
              <b> piqd-Kanäle abonnieren -</b>
              <br />
              und <b>besser informiert sein.</b>
            </H3>

            <NavLink
              as="a"
              href="https://apps.apple.com/app/id1576473812"
              target="_blank"
              display="inline-flex"
              alignItems="center"
              textDecoration="none"
              color="inherit"
            >
              <H3
                fontSize={['12px', '15px']}
                lineHeight={['2.9rem', '2.9rem', '4rem']}
                backgroundColor={['green']}
                textAlign={['center']}
                marginTop={['8rem', '10rem']}
                marginBottom={['1rem']}
                marginLeft={['29.5rem', '25rem', '24rem']}
                width={['20rem', '30rem']}
                padding={['0.6rem']}
              >
                Hier gehts zum Appstore
              </H3>
            </NavLink>
            <H3
              fontSize={['15px']}
              lineHeight={['2.9rem', '2.9rem', '4rem']}
              textAlign={['center']}
              fontWeight={['500']}
              marginBottom={['8rem', '10rem']}
            >
              Für alle iphone ab 6s
            </H3>
            <Div></Div>
            <NavLink
              as="a"
              href="https://www.piqd.de/"
              target="_blank"
              textDecoration="none"
              color="inherit"
            >
              <Image
                fixed={data.piqd_logo.childImageSharp.fixed}
                alt="PiqdLogo"
                marginTop={['7rem']}
                style={{
                  marginLeft: '30rem',
                }}
              />
            </NavLink>
            <H3
              fontSize={['15px']}
              lineHeight={['2.9rem', '2.9rem', '4rem']}
              textAlign={['center']}
              fontWeight={['500']}
              marginTop={['7rem']}
            >
              powered by
            </H3>

            <NavLink
              as="a"
              href="https://www.justt.me/"
              target="_blank"
              textDecoration="none"
              color="inherit"
            >
              <Div marginLeft={['35rem']}>
                <svg width="125" height="55" xmlns="http://www.w3.org/2000/svg">
                  <g transform="translate(0 -9)" fill="none" fill-rule="evenodd">
                    <path
                      d="M10.137 38.586c6.035 0 9.629-3.418 9.629-9.414V9.816h-5.899v19.317c0 2.832-1.328 4.355-3.789 4.355-2.226 0-3.71-1.504-3.75-3.789H.605c0 5.39 3.77 8.887 9.532 8.887zm21.738-.273c3.262 0 5.371-1.504 6.465-4.2h.117V38h5.508V16.496h-5.703v12.246c0 2.91-1.66 4.824-4.317 4.824-2.636 0-4.082-1.562-4.082-4.472V16.496H24.16v13.79c0 5 3.067 8.026 7.715 8.026zm24.57.058c5.625 0 9.473-2.754 9.473-6.934 0-3.203-1.719-4.863-6.035-5.84l-4.043-.878c-1.934-.45-2.871-1.172-2.871-2.246 0-1.387 1.367-2.305 3.418-2.305 2.187 0 3.613 1.074 3.73 2.773h5.274c-.079-4.101-3.614-6.816-8.926-6.816-5.45 0-9.004 2.656-9.004 6.7 0 3.144 2.09 5.234 6.133 6.152l3.965.898c1.953.469 2.695 1.055 2.695 2.168 0 1.406-1.387 2.285-3.652 2.285-2.364 0-3.829-1.016-4.102-2.832h-5.566c.293 4.14 3.847 6.875 9.511 6.875zm20.899-.293c1.152 0 2.168-.117 2.754-.234v-4.219a9.798 9.798 0 01-1.426.098c-1.738 0-2.559-.801-2.559-2.461v-10.45h3.985v-4.316h-3.985v-4.785H70.41v4.785H67.5v4.317h2.91v11.386c0 4.2 1.953 5.88 6.934 5.88zm14.472 0c1.153 0 2.168-.117 2.754-.234v-4.219a9.798 9.798 0 01-1.425.098c-1.739 0-2.56-.801-2.56-2.461v-10.45h3.985v-4.316h-3.984v-4.785h-5.703v4.785h-2.91v4.317h2.91v11.386c0 4.2 1.953 5.88 6.933 5.88z"
                      fill="#000"
                      fill-rule="nonzero"
                    />
                    <circle fill="#00FF24" cx="4" cy="14" r="4" />
                  </g>
                </svg>{' '}
              </Div>
            </NavLink>
          </Div>
        </Section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query getData3 {
    medwatch_landing: file(relativePath: { eq: "our-publishers/Justt_Demo_Graphics_4.png" }) {
      childImageSharp {
        fixed(width: 850, height: 650) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    piqd_logo: file(relativePath: { eq: "our-publishers/piqd_landing.png" }) {
      childImageSharp {
        fixed(width: 200, height: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default IndexPage;
